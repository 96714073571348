<template>
	<section>
		<h2 class="accessible-hide">{{ sectionTitle }}</h2>
		<component
			:is="current_breakpoint.template"
			:section-title="sectionTitle"
			:data="data"
			:show-section-title="showSectionTitle"
		/>
	</section>
</template>

<script>
import { get } from '@/helpers';
import baseComponent from '@/mixins/baseComponent';
import TaggedContentList from './templates/TaggedContent_List';

export default {
	name: 'TaggedContent',

	mixins: [baseComponent],

	components: {
		TaggedContentList,
	},

	props: {
		slug: {
			type: String,
			required: true,
		},
		showSectionTitle: {
			type: Boolean,
			default: true,
		},
	},

	methods: {
		get_data() {
			let request_options = {
				type: 'tag',
				slug: this.slug,
			};

			get(
				`${this.$root.proxy}/services/content.ashx/get`,
				request_options,
			)
				.then(response => {
					this.original_data = response.content.items.slice(
						0,
						this.count,
					);
					this.setup_breakpoints();
				})
				.catch(error => {
					console.log(error);
				});
		},
	},

	watch: {
		$route() {
			this.get_data();
		},
	},

	created() {
		this.get_data();
	},
};
</script>

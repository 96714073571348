<template>
	<div class="component c-archives c-archives--media-center">
		<header
			class="c-archives__header border-b line-height-100 margin-b-12 padding-b-16 bp-1024:margin-b-24"
		>
			<div
				class="c-archives__header-title font-size-18 font-weight-700 line-height-100 margin-r-auto text-brand bp-1024:font-size-24"
			>
				News
			</div>
		</header>
		<div
			class="c-archives__filters-container row margin-b-12 bp-1024:margin-b-24"
		>
			<div
				class="col-1-of-1 margin-b-12 bp-1024:padding-r-24 bp-1024:margin-b-0 bp-1024:col-1-of-4"
			>
				<autocomplete
					:items="$root.divisions"
					placeholder="Select Division..."
					@item-selected="set_selected_division"
				/>
			</div>
			<div
				class="col-1-of-1 margin-b-12 bp-1024:padding-r-24 bp-1024:margin-b-0 bp-1024:col-1-of-4"
			>
				<autocomplete
					:items="$root.tags"
					placeholder="Select Tag..."
					@item-selected="set_selected_tag"
				/>
			</div>

			<div
				class="c-archives__search border display-flex col-1-of-1 bp-1024:col-2-of-4"
			>
				<input
					placeholder="Search"
					type="text"
					class="c-archives__search-input font-size-14 font-weight-bold padding-x-24 text-brand reset"
					v-model="search_query"
					@keyup.enter="search_archives"
				/>
				<button
					class="c-archives__search-icon border-l cursor-pointer padding-x-24 reset text-brand transition hover:bg-color-primary hover:text-on-primary"
					@click.prevent="search_archives"
				>
					<search-icon class="height-100" size="20"></search-icon>
				</button>
			</div>
		</div>
		<div class="row" v-if="data && data.length && !loading">
			<div class="col-1-of-1 bp-1024:padding-r-24 bp-1024:col-2-of-3">
				<div class="c-archives__feature">
					<div
						class="c-archives__media position-relative bg-color-surface"
					>
						<picture
							class="c-archives__picture  object-fit-cover object-position-c"
							v-if="feature_story.story_image"
						>
							<source
								media="(min-width: 1024px)"
								:srcset="
									`${$root.proxy}${feature_story.story_image}?width=1920&height=1080&mode=crop&format=jpg&quality=80`
								"
							/>
							<source
								media="(min-width: 641px)"
								:srcset="
									`${$root.proxy}${feature_story.story_image}?width=1024&height=576&mode=crop&format=jpg&quality=80`
								"
							/>
							<img
								class="width-100"
								v-lazy="
									`${$root.proxy}${feature_story.story_image}?width=640&height=360&mode=crop&format=jpg&quality=80`
								"
								:alt="feature_story.image_alt_text"
							/>
						</picture>
						<img
							class="object-fit-cover object-position-c"
							src="@/assets/default.jpg"
							v-else
						/>
					</div>
					<div class="c-archives__details padding-t-24">
						<div
							class="c-archives__title font-size-18 bp-768:font-size-24 bp-1024:font-size-36 font-weight-700 margin-t-0 margin-b-12 padding-l-16"
						>
							{{ feature_story.story_headline }}
						</div>
						<div
							class="c-archives__subtitle text-muted font-size-16 padding-l-24"
							v-html="feature_story.story_summary"
						></div>
						<navigation-link
							_class="c-archives__url display-flex align-items-center font-size-16 font-weight-700 margin-t-12 margin-l-24 text-default text-decoration-none transition hover:text-brand"
							:url="feature_story.story_path"
						>
							Read Here
							<arrow-right-circle-icon
								class="c-archives__icon font-weight-400 margin-l-12 text-brand transition"
							></arrow-right-circle-icon>
						</navigation-link>
					</div>
				</div>
			</div>
			<div
				class="col-1-of-1 padding-t-12 bp-1024:padding-t-0 bp-1024:col-1-of-3"
			>
				<ul class="reset-list c-archives__list">
					<li
						class="c-archives__item border-b border-style-dashed font-size-16 border-b padding-y-12"
						:class="{
							'border-t bp-1024:border-t-none': index === 0,
						}"
						v-for="(story, index) in data"
						:key="index"
					>
						<div class="c-archives__date text-muted">
							{{ story.story_postdate }}
						</div>
						<navigation-link
							_class="display-block text-decoration-none hover:text-decoration-underline"
							:url="story.story_path"
							>{{ story.story_headline }}</navigation-link
						>
					</li>
				</ul>
				<navigation-link
					url="/about/resources/media-center/news"
					_class="c-archives__archives display-block font-weight-700 hover:text-decoration-underline margin-t-12 text-align-center text-decoration-none"
					>More News</navigation-link
				>
			</div>
		</div>
		<div
			class="c-archives__no-data text-align-center"
			v-if="!loading && data && !data.length"
		>
			There are no stories that match selected filters.
		</div>
		<loading-indicator vertical-space="20" v-if="loading" />
	</div>
</template>

<script>
import { get } from '@/helpers';
import { ArrowRightCircleIcon, SearchIcon } from 'vue-feather-icons';
import Autocomplete from '@/components/Common/Autocomplete';
import NavigationLink from '@/components/Common/NavigationLink';
import baseComponent from '@/mixins/baseComponent';
import LoadingIndicator from '@/components/Common/LoadingIndicator';

export default {
	name: 'StoriesMediaCenter',

	mixins: [baseComponent],

	components: {
		Autocomplete,
		NavigationLink,
		ArrowRightCircleIcon,
		SearchIcon,
		LoadingIndicator,
	},

	data: () => ({
		selected_division: 0,
		selected_tag: 0,
		loading_stories: false,
		search_query: '',
	}),

	computed: {
		feature_story() {
			return this.data[0];
		},
	},

	methods: {
		search_archives() {
			this.$router.push({
				name: 'Stories Archives',
				query: { q: this.search_query },
			});
		},
		get_data() {
			this.loading = true;

			let request_options = {
				index: 1,
				page_size: 10,
				division: this.selected_division,
				sport: 2,
				season: 0,
				tag: this.selected_tag,
				search: this.search_query,
			};

			get(
				`${this.$root.proxy}/services/archives.ashx/stories`,
				request_options,
			)
				.then(response => {
					this.original_data = response;
					this.setup_breakpoints();
					this.loading = false;
				})
				.catch(error => {
					console.log(error);
					this.loading = false;
				});
		},
		set_selected_division(division) {
			if (division) {
				this.selected_division = division.id;
			} else {
				this.selected_division = 0;
			}
		},
		set_selected_tag(tag) {
			if (tag) {
				this.selected_tag = tag.id;
			} else {
				this.selected_tag = 0;
			}
		},
	},

	watch: {
		selected_division(newVal, oldVal) {
			if (newVal != oldVal) {
				this.get_data();
			}
		},
		selected_tag(newVal, oldVal) {
			if (newVal != oldVal) {
				this.get_data();
			}
		},
	},

	created() {
		this.get_data();
	},
};
</script>

<style lang="scss" scoped>
.c-archives {
	//for naming
	&__filters {
		&-button {
			height: 65px;
			.c-archives__filters-item:not(.is-active) & {
				color: #a0a0a0;
			}
		}
	}
	&__select {
		height: 65px;
		&:hover,
		&:focus {
			cursor: pointer;
		}
		&-icon {
			pointer-events: none;
		}
	}
	&__search {
		height: 65px;

		&-input {
			flex: 1 1 auto;
		}
		&-icon {
			flex: 0 0 auto;
		}
	}
	&__feature &__title {
		border-left: 6px solid var(--color-primary);
		font-family: mislab-std, sans-serif;
	}
	&__header-title {
		font-family: mislab-std, sans-serif;
	}
}
</style>

<style lang="scss">
.c-archives--media-center {
	.c-archives {
		//for naming
		&__feature &__url {
			&:hover,
			&:focus {
				.c-archives__icon {
					transform: translate(6px);
				}
			}
		}
	}
}
</style>

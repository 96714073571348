<template>
	<main class="media-center bg-color-surface position-relative">
		<div
			class="media-center__header display-flex align-items-center overflow-hidden justify-content-center text-on-primary position-relative"
		>
			<div
				class="media-center__header-title font-size-36 font-weight-700 line-height-150 position-relative z-index-3"
			>
				Media Center
			</div>
			<div
				class="media-center__header-image utility-position-center width-100 z-index-1"
			>
				<img
					class="media-center__header-image object-position-c object-fit-cover"
					src="@/assets/media_center_banner.jpg"
					alt=""
				/>
			</div>
		</div>
		<div
			class="media-center__top bg-color-white row max-width-grid display-block position-relative z-index-2"
		>
			<archives
				class="margin-x-12 padding-y-24 bp-1024:padding-t-32 bp-1024:padding-b-48 bp-1024:margin-x-24"
				template="StoriesMediaCenter"
				section-title="Stories"
				:breakpoints="{
					0: { count: 5, skip: 0, template: 'Archives' },
					1024: { count: 7, skip: 0, template: 'Archives' },
				}"
			/>
			<youtube
				class="border-t border-style-dotted margin-x-12 padding-y-24 bp-1024:padding-t-48 bp-1024:padding-b-40 bp-1024:margin-x-24"
				template="YoutubeSlider"
				section-title="Youtube"
				:breakpoints="{
					0: { count: 3, skip: 0, template: 'YoutubeList' },
					540: { count: 7, skip: 0, template: 'YoutubeSlider' },
				}"
			/>
		</div>
		<div
			class="media-center__middle bg-color-white row max-width-grid margin-y-24 width-100"
			style="box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);"
		>
			<media-contacts
				class="margin-x-12 padding-y-24 
					bp-1024:padding-t-48 bp-1024:padding-b-40 bp-1024:margin-x-0 bp-1024:padding-x-24"
				template="MediaContactsSlider"
				section-title="Media Contacts"
			/>
		</div>
		<div
			class="media-center__bottom bg-color-white row max-width-grid width-100
				bp-1024:padding-y-24 bp-1024:margin-y-12"
		>
			<tabs
				class="padding-x-12 padding-y-24 bp-1024:display-none width-100"
				:options="{ useUrlFragment: false }"
			>
				<tab name="Statements">
					<tagged-content
						template="TaggedContentList"
						section-title="Statements"
						slug="/themes-topics/statement"
						:count="5"
						:show-section-title="false"
					></tagged-content>
				</tab>
				<tab name="Governance">
					<tagged-content
						template="TaggedContentList"
						section-title="Governance"
						slug="/themes-topics/governance"
						:count="5"
						:show-section-title="false"
					></tagged-content>
				</tab>
				<tab name="Infractions Process">
					<tagged-content
						template="TaggedContentList"
						section-title="Infractions Process"
						slug="/themes-topics/infractions-process"
						:count="5"
						:show-section-title="false"
					></tagged-content>
				</tab>
			</tabs>
			<div
				class="display-none bp-1024:display-flex row max-width-grid width-100"
			>
				<tagged-content
					class="col-1-of-3 padding-r-12 padding-l-24 padding-y-24"
					template="TaggedContentList"
					section-title="Statements"
					slug="/themes-topics/statement"
					:count="5"
				></tagged-content>
				<tagged-content
					class="col-1-of-3 padding-x-12 padding-y-24"
					template="TaggedContentList"
					section-title="Governance"
					slug="/themes-topics/governance"
					:count="5"
				></tagged-content>
				<tagged-content
					class="col-1-of-3 padding-l-12 padding-r-24 padding-y-24"
					template="TaggedContentList"
					section-title="Infractions Process"
					slug="/themes-topics/infractions-process"
					:count="5"
				></tagged-content>
			</div>
			<slideshow
				class="padding-x-12 padding-y-24 width-100"
				:slideshow-id="6"
				template="SlideshowCardSlider"
				section-title="Resources"
				:count="6"
			/>
		</div>
		<content-stream
			template="ContentStreamPackery"
			section-title="Connect With Us"
			:sport_id="2"
			:breakpoints="{
				0: { count: 4, skip: 0, template: 'ContentStreamPackery' },
				1024: { count: 7, skip: 0, template: 'ContentStreamPackery' },
			}"
		></content-stream>
	</main>
</template>

<script>
import Tabs from '@/components/Common/Tabs';
import Tab from '@/components/Common/Tab';
import TaggedContent from '@/components/tagged_content/TaggedContent';
import ContentStream from '@/components/contentstream/ContentStream';
import Slideshow from '@/components/slideshow/Slideshow';
import Youtube from '@/components/youtube/Youtube';
import Archives from '@/components/archives/Archives';
import MediaContacts from '@/components/media_contacts/MediaContacts';

export default {
	name: 'MediaCenter',

	components: {
		Tabs,
		Tab,
		TaggedContent,
		ContentStream,
		Slideshow,
		Youtube,
		Archives,
		MediaContacts,
	},

	created() {
		this.setMetaSidearm('Media Center');
	},
};
</script>

<style lang="scss" scoped>
.media-center {
	&__header {
		height: 150px;
		&-title{
			font-family: mislab-std, sans-serif;
		}
		&-image {
			&::before {
				content: '';
				background: rgba(0, 0, 0, 0.65);
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				z-index: 2;
			}
		}
	}
	&__top {
		border-top: 6px solid var(--color-primary);
	}
	&__top,
	&__bottom {
		box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
	}
}

@media screen and (min-width: 1024px) {
	.media-center {
		&__header {
			height: 270px;
		}
	}
}
@media screen and (min-width: 1440px) {
	.media-center {
		&__top {
			margin-top: -48px;
		}
	}
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs-component"},[_c('ul',{staticClass:"tabs-component-tabs reset-list display-flex",attrs:{"role":"tablist"}},_vm._l((_vm.tabs),function(tab,i){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(tab.isVisible),expression:"tab.isVisible"}],key:i,staticClass:"tabs-component-tab flex-item-1 flex-shrink-0",class:{
				'is-active': tab.isActive,
				'is-disabled': tab.isDisabled,
			},attrs:{"role":"presentation"}},[_c('a',{staticClass:"tabs-component-tab-a utility-flex-center text-decoration-none font-size-18 text-default hover:text-brand",class:{
					'font-weight-500 text-brand border-t border-l border-r':
						tab.isActive,
					'border-b': !tab.isActive,
				},staticStyle:{"height":"65px"},attrs:{"aria-controls":tab.hash,"aria-selected":tab.isActive,"href":tab.hash,"role":"tab"},domProps:{"innerHTML":_vm._s(tab.header)},on:{"click":function($event){return _vm.selectTab(tab.hash, $event)}}})])}),0),_c('div',{staticClass:"tabs-component-panels margin-t-12"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
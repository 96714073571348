<template functional>
	<div class="component c-tagged-content c-tagged-content--list">
		<div
			class="c-tagged-content__title border-b text-brand font-size-24 font-weight-700 line-height-100 padding-b-16"
			v-if="props.sectionTitle && props.showSectionTitle"
		>
			{{ props.sectionTitle }}
		</div>
		<ul class="c-tagged-content__list reset-list" v-if="props.data.length">
			<li
				class="c-tagged-content__item border-b line-height-150 padding-y-12"
				v-for="(item, index) in props.data"
				:key="index"
			>
				<div
					class="c-tagged-content__date font-size-16 text-muted"
					v-luxon:date_full="item.story_postdate"
				></div>
				<component
					_class="display-block font-size-18 text-decoration-none hover:text-decoration-underline"
					:url="item.story_path"
					:is="injections.components.NavigationLink"
					>{{ item.story_headline }}</component
				>
			</li>
		</ul>
		<div class="c-tagged-content__no-data padding-t-12" v-else>
			<span>There is currently no tagged content for #</span>
			<span class="text-transform-lowercase"
				>{{ props.sectionTitle }}.</span
			>
		</div>
	</div>
</template>

<script>
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'TaggedContentList',

	inject: {
		components: {
			default: {
				NavigationLink,
			},
		},
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		sectionTitle: {
			type: String,
		},
		showSectionTitle: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.c-tagged-content {
	//for naming
	&__title,
	&__item {
		border-bottom-style: dotted;
	}
}
</style>

<template>
	<div
		class="component c-media-contacts c-media-contacts--slider position-relative"
		v-if="data && data.length"
	>
		<header
			class="c-media-contacts__header border-b border-style-dotted display-flex align-items-center line-height-100 margin-b-12 padding-b-16 bp-1024:margin-b-24"
		>
			<div
				class="c-media-contacts__header-title font-size-18 font-weight-700 line-height-100 margin-r-auto text-brand bp-1024:font-size-24"
			>
				Media Contacts
			</div>
			<router-link
				class="c-media-contacts__header-inquiry font-size-16 font-weight-700 text-decoration-none hover:text-decoration-underline"
				to="/about/resources/media-center/media-inquiries"
				>Inquiry Form</router-link
			>
		</header>
		<swiper
			class="c-media-contacts__slider swiper"
			ref="swiper_slider"
			:options="swiper_options"
			v-if="data && data.length"
		>
			<swiper-slide
				class="c-media-contacts__item"
				v-for="(item, index) in data"
				:key="index"
			>
				<contact-card :contact="item" />
			</swiper-slide>
		</swiper>
		<div
			class="media-contacts-swiper-button-prev swiper-button-prev"
			slot="button-prev"
		>
			<arrow-left-icon size="24"></arrow-left-icon>
		</div>
		<div
			class="media-contacts-swiper-button-next swiper-button-next"
			slot="button-next"
		>
			<arrow-right-icon size="24"></arrow-right-icon>
		</div>
	</div>
</template>

<script>
import { ArrowRightIcon, ArrowLeftIcon } from 'vue-feather-icons';
import ContactCard from '@/components/Common/ContactCard';

export default {
	name: 'MediaContactSlider',

	components: {
		ArrowRightIcon,
		ArrowLeftIcon,
		ContactCard,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},

	data: () => ({
		swiper_options: {
			spaceBetween: 24,
			touchReleaseOnEdges: true,
			navigation: {
				nextEl: '.media-contacts-swiper-button-next',
				prevEl: '.media-contacts-swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			breakpoints: {
				540: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
		},
	}),
};
</script>

<style lang="scss" scoped>
.c-media-contacts--slider {
	.swiper-container {
		padding-bottom: 48px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		bottom: 0;
		top: auto;
		left: calc(50% - 48px);
		height: 48px;
		width: 48px;

		&::after {
			content: none;
		}
	}

	.swiper-button-next {
		margin-left: 48px;
	}
}
</style>

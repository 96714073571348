<template>
	<section class="width-100" v-if="original_data">
		<h2 class="accessible-hide">{{ sectionTitle }}</h2>
		<component :is="current_breakpoint.template" :data="data" />
	</section>
</template>

<script>
import { get } from '@/helpers';
import baseComponent from '@/mixins/baseComponent';
import MediaContactsSlider from './templates/MediaContacts_Slider';

export default {
	name: 'MediaContact',

	mixins: [baseComponent],

	components: {
		MediaContactsSlider,
	},

	methods: {
		get_data() {
			let request_options = {
				type: 'media-contacts',
				start: this.start,
				count: this.count,
			};

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			)
				.then(response => {
					this.original_data = response;
					this.setup_breakpoints();
				})
				.catch(error => {
					console.log(error);
				});
		},
	},

	watch: {
		$route() {
			this.get_data();
		},
	},

	created() {
		this.get_data();
	},
};
</script>

<style></style>
